import React from 'react'

function ReactCheatsheetPage() {
  React.useEffect(() => {
    window.location.href =
      'https://pfftdammitchris.github.io/react-csheets-demo'
  }, [])
  return <div />
}

export default ReactCheatsheetPage
